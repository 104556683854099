import * as React from "react"
import { Header, RecipeOpen, Footer, BottomNavbar } from "../components/index"
import { Helmet } from "react-helmet"

import mockup from "../utils/data"

const IndexPage = ({ data }) => {
  return (
    <div>
      <Helmet>
        <title>Esteekey - Recipe Opened</title>
        <meta name="description" content="Esteekey - #1 Fitness app for 2022" />
        <meta name="theme-color" content="#ffffff" data-react-helmet="true" />
      </Helmet>
      <Header />
      <div className="min-h-[calc(100vh-158px)] pt-[69px] xl:pt-0">
        <RecipeOpen
          title={mockup.recipe_post.title}
          overview={mockup.recipe_post.overview}
          url={mockup.recipe_post.url}
          ingredients={mockup.recipe_post.ingredients}
          image={mockup.recipe_post.image}
          content={mockup.recipe_post.content}
          steps={mockup.recipe_post.steps}
        />
      </div>
      <BottomNavbar />
      <Footer />
    </div>
  )
}

export default IndexPage
